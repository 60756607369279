import { useNavigate } from "react-router-dom";
import { VNRButton } from "../../components/Forms/VNRButton/VNRButton";
import { useTranslation } from "react-i18next";

const HPContactUs: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <div className="HPContactus">
      <div className="HPCU-container">
        <div className="HPCU-content">
          <div className="HPCU-heading">{t("HP_CONTACT_US_TEXT1")}</div>
          <div className="HPCU-sub-heading">{t("HP_CONTACT_US_TEXT2")}</div>
          <VNRButton
            translationKey="HP_CONTACT_US_BUTTON"
            className="buttonprops"
            variant="outlined"
            onClick={() => {
              navigate("/contact");
            }}
          />
        </div>
        <div>
          <img
            className="HPCU-img"
            src="./SearchingForPartner.jpg"
            alt="seaching for partner"
          />
        </div>
      </div>
    </div>
  );
};

export { HPContactUs };
