import React from "react";
import useContactUsForm from "../../hooks/useContactUsForm";
import "./ContactUs.css";
import { useTranslation } from "react-i18next";
const ContactUs: React.FC = () => {
  const { formData, errors, handleFormDataChange, handleSubmitForm, loading } =
    useContactUsForm();
    const { t } = useTranslation();

  return (
    <div className="CU-Background">
      <div className="CU-Texts">
        <div className="text1">
          <h1> {t("CU_BANNER_HEADER")}</h1>
        </div>
        <div className="text2">
          <h5>{t("CU_BANNER_TEXT1")}</h5>
          <p>
            {t("CU_BANNER_TEXT2")}
          </p>
        </div>
      </div>
      <div className="CU-body">
        <div className="CU-form">
          <form>
            <label htmlFor="name"> {t("CU_BANNER_LABEL1")}</label>
            <input
              type="text"
              id="name"
              name="name"
              placeholder={t("CU_BANNER_PLACEHOLDER1")}
              value={formData.name}
              onChange={handleFormDataChange}
            />
            {errors?.name && (
              <p
                style={{
                  color: "red",
                }}
              >
                {errors?.name}
              </p>
            )}
            <label htmlFor="email"> {t("CU_BANNER_LABEL2")}</label>
            <input
              type="email"
              id="email_id"
              name="emailId"
              placeholder={t("CU_BANNER_PLACEHOLDER2")}
              value={formData.emailId}
              onChange={handleFormDataChange}
            />
            {errors?.emailId && (
              <p
                style={{
                  color: "red",
                }}
              >
                {errors?.emailId}
              </p>
            )}

            <label htmlFor="subject">{t("CU_BANNER_LABEL3")}</label>
            <input
              type="text"
              id="subject"
              name="subject"
              placeholder={t("CU_BANNER_PLACEHOLDER3")}
              value={formData.subject}
              onChange={handleFormDataChange}
            />
            {errors?.subject && (
              <p
                style={{
                  color: "red",
                }}
              >
                {errors?.subject}
              </p>
            )}

            <label htmlFor="message">{t("CU_BANNER_LABEL4")}</label>
            <textarea
              id="message"
              name="message"
              placeholder={t("CU_BANNER_PLACEHOLDER4")}
              rows={6}
              value={formData.message}
              onChange={handleFormDataChange}
            ></textarea>
            {errors?.message && (
              <p
                style={{
                  color: "red",
                }}
              >
                {errors?.message}
              </p>
            )}

            <button type="submit" onClick={handleSubmitForm} disabled={loading}>
            {t("CU_BANNER_BUTTON")}
            </button>
          </form>
        </div>
        <div className="CU-map">
          <div className="map">
            <iframe
              title="vnroyal-map"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d31355.96422507984!2d106.62419444379692!3d10.773308220000297!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31752f0abb09f651%3A0x35f41d7a67122864!2zQ8OUTkcgVFkgQ-G7lCBQSOG6pk4gVFJBTkcgVEhJ4bq-VCBC4buKIC0gVuG6rFQgVMavIFkgVOG6viBWTlJPWUFM!5e0!3m2!1sen!2s!4v1718440331372!5m2!1sen!2s"
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
          <div className="CU-address">
            <h4>{t("CU_BANNER_ADDRESS")}</h4>
            <p>{t("CU_BANNER_ADDRESS1")}</p>
          </div>
          <div className="CU-Link">
            <a
              href="https://www.google.com/maps/search/?api=1&query=10.767173740297672, 106.66113147623142"
              rel="noreferrer"
              target="_blank"
            >
              {t("CU_BANNER_LINK")}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
