import { Button, ButtonProps, CircularProgress } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

/**
 * @interface IVNRButton
 */
export interface IVNRButton extends ButtonProps {
  /**
   * @prop translationKey
   * translation key for the button title.
   */
  translationKey: string;

  /**
   * @prop loading flag determines the button's
   * loading state.
   */
  loading?: boolean;

  /**
   * @prop anchor takes in the ref of any popper element.
   */
  anchor?: React.MutableRefObject<any>;

  /**
   * @prop image renders an image in the button
   */
  image?: any;
}

/**
 * @component VNRButton renders the button with translated text.
 *
 * @param IVNRButton
 */
const VNRButton: React.FC<IVNRButton> = ({
  translationKey,
  loading,
  anchor,
  image,
  ...buttonProps
}) => {
  const { t } = useTranslation();

  const loaderIcon = (
    <CircularProgress size={24} color="inherit" aria-label="loading-button" />
  );

  const btnText = t(translationKey);

  return (
    <Button
      {...buttonProps}
      {...(anchor && { ref: anchor })}
      {...(loading && { endIcon: loaderIcon, disabled: true })}
    >
      {btnText}
      {image}
    </Button>
  );
};

export { VNRButton };
