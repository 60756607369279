import React, { useEffect, useMemo, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./NavBar.css";
import LanguageSelector from "../LanguageDropdown";
import { useTranslation } from "react-i18next";

interface NavBarProps {
  logoSrc: string;
  language: string;
}

const NavBar: React.FC<NavBarProps> = ({ logoSrc, language }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const { pathname } = useLocation();
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [pathname]);

  const handleMenuIconClick = () => {
    if (!menuOpen) {
      document.body.classList.toggle("no-scroll", !menuOpen);
    } else {
      document.body.classList.remove("no-scroll");
    }
    setMenuOpen(!menuOpen);
  };

  const navLinks = useMemo(() => {
    return [
      { href: "/ourservices", text: t("NAVBAR_TEXT1") },
      { href: "/about", text: t("NAVBAR_TEXT2") },
      { href: "/contact", text: t("NAVBAR_TEXT3") },
    ];
  }, [t]);

  return (
    <>
      <nav id="navbar" className="navbar">
        <div className="navbar-container">
          <div className="navbar-content">
            <div className="navbar-logo">
              <Link to="/">
                <img src={logoSrc} alt="Company Logo" />
              </Link>
            </div>
            <ul className="navbar-links">
              {navLinks.map((link, index) => (
                <li key={index}>
                  <Link to={link.href}>{link.text}</Link>
                </li>
              ))}
            </ul>
          </div>
          <div className="language-select">
            <LanguageSelector color={"white"} />
          </div>
        </div>
      </nav>
      <nav className="mobile-navbar">
        <div className="navbar-logo">
          <Link to="/">
            <img src={logoSrc} alt="Company Logo" />
          </Link>
        </div>
        <div
          id="nav-icon2"
          className={`menu-icon ${menuOpen ? "open" : ""}`}
          onClick={handleMenuIconClick}
        >
          <>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </>
        </div>
        <div className={`menu ${menuOpen ? "open" : ""}`}>
          <div className="divider"></div>
          <ul className="mobile-nav-list">
            {navLinks.map((link, index) => (
              <li key={index} onClick={handleMenuIconClick}>
                <Link to={link.href}>{link.text}</Link>
              </li>
            ))}
          </ul>
          <div className="language-select">
            <LanguageSelector color={"black"} />
          </div>
        </div>
      </nav>
      <div
        id="overlay"
        className={`overlay ${menuOpen ? "show" : ""}`}
        onClick={handleMenuIconClick}
      ></div>
    </>
  );
};

export default NavBar;
