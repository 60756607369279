import { HPBannerSection } from "./HPBannerSection";
import { HPContactUs } from "./HPContactUs";
import HpOurServices from "./HpOurServices";

const HomePage: React.FC = () => {
  return (
    <>
      <HPBannerSection />
	    <HpOurServices />
      <HPContactUs />
    </>
  );
};

export { HomePage };
