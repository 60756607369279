import React from 'react'
import './OurLead.css'
import { useTranslation } from 'react-i18next';
const OurLead:React.FC = () => {
  const { t } = useTranslation();
  return (
    <section className='OL-background'>
        <div className='OL-header'>
          <div></div>
           <h2>{t("AU_OUR_LEAD_HEADER")}</h2>
           <div></div>
        </div>
        <div className='OL-body'>
            <div className='OL-part1'>
             <div className='OL-Texts1'>
                <div className='Texts1-text1'>
                  <h3> {t("AU_OUR_LEAD_NAME1")}</h3>
                  <h5> {t("AU_OUR_LEAD_TEXT1")}</h5>
                </div>
                <div className='Texts1-text2'>
                  <p>{t("AU_OUR_LEAD_TEXT2")}</p>
                </div>
             </div>
             <div className='image1'>
             <div className='OL-image1'>
                <img src='./DrLan.jpg' alt='LeadImage' />
             </div>
             </div>
           </div>
            <div className='OL-part2'>
              <div className='image2'>
                <div className='OL-image2'>
                  <img src='./DrNhon.jpg' alt='LeadImage' />
                </div>
              </div>
              <div className='OL-Texts2'>
                <div className='Texts2-text1'>
                  <h3>{t("AU_OUR_LEAD_NAME2")}</h3>
                  <h5>{t("AU_OUR_LEAD_TEXT1")}</h5>
                </div>
                 <div className='Texts2-text2'>
                    <p>{t("AU_OUR_LEAD_TEXT3")}</p>
                 </div>
             </div>
           </div>
        </div>
     </section>
  )
}

export default OurLead
