import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import translationEN from "./locales/en/translation.json";
import translationVI from "./locales/vi/translation.json";

const resources = {
    en: {
      translation: translationEN
    },
    vi: {
      translation: translationVI
    }
  };

/**
 * Initialize i18n instance on application 
 * start up, the i18n instance contains the
 * configuration to read translation.json and
 * displaying the current language of the application.
 */
i18n
  /**
   * i18n Backend is instantiated to read translation
   * files from the public folder.
   */
  .use(Backend)
  /**
   * Detects the language and helps to read the
   * respective translation.json for the app.
   */
  .use(initReactI18next)
  /**
   * Initial configuration for the i18n plugin.
   */
  .init({
    /**
     * contains all the translation files with languagecode as key
     */
    resources,
    /**
     * Fallback lang is en-US incase translation.json
     * is not available for detected language.
     */
    fallbackLng: 'en',

    /**
     * Current default language is en-US.
     */
    lng: 'en',

    /**
     * we do not use keys in form messages.welcome
     */
    keySeparator: false, 

    /**
     * detection order for translation.json
     */
    detection: {
      // Search for queryString and then cookie.
      order: ['queryString', 'cookie'],

      // Cache the json in cookie.
      cache: ['cookie'],
    },
    /* This prop is not required for react applications.*/
    interpolation: {
      escapeValue: false,
    },
  });

// Export.
export default i18n;