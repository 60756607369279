import React from "react";
import { VNRButton } from "../../components/Forms/VNRButton/VNRButton";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const HpOurServices: React.FC = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  return (
    <section className="HP-OurServices">
      <div className="services-left">
        <div className="service">
          <div className="service-box-left ui-ux-design"></div>
          <div className="service-box-right web-development">
            <div className="content">{t("HP_OUR_SERVICES_TEXT1")}</div>
          </div>
        </div>
        <div className="service">
          <div className="service-box-left digital-marketing"></div>
          <div className="service-box-right ui-ux-design">
            <div className="content">{t("HP_OUR_SERVICES_TEXT2")}</div>
          </div>
        </div>
        <div className="service">
          <div className="service-box-left web-development"></div>
          <div className="service-box-right digital-marketing">
            <div className="content">{t("HP_OUR_SERVICES_TEXT3")}</div>
          </div>
        </div>
      </div>
      <div className="services-right">
        <div>{t("HP_OUR_SERVICES_TEXT4")} </div>
        <VNRButton
          translationKey="HP_OUR_SERVICES_BUTTON"
          className="our-services-btn"
          variant="outlined"
          onClick={() => {
            navigate("/ourservices");
          }}
        ></VNRButton>
      </div>
    </section>
  );
};

export default HpOurServices;
