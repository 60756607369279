import React from 'react'
import './OurPartners.css'
import {DeRoyalLogoWB, FELIXLogoWB, VLRLogoWB} from '../../icons'
import { useTranslation } from 'react-i18next';
const OurPartners: React.FC = () => {
  const { t } = useTranslation();
  return (
    <section className='OP-background'>
      <div className='OP-Header'>
        <span></span>
        <h2> {t("AU_OUR_PARTNERS_HEADER")}</h2>
        <span></span>
      </div>
      <div className='OP-logos'>
       <div><FELIXLogoWB /></div>
       <div>< VLRLogoWB /></div>
       <div><DeRoyalLogoWB /></div>
      </div>
    </section>
  )
}

export default OurPartners
