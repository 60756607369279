import React from 'react'
import './MissionVision.css'
import { useTranslation } from 'react-i18next';
const MissionVision: React.FC = () => {
  const { t } = useTranslation();
  return (
    <section className='MV-Background'>
      <div className='MV-mission'>
        <h2 style={{borderRadius:'0 0 70px 70px'}}><div className='MV-Text'> {t("AU_OUR_MISSION_HEADER")}</div></h2>
       <div className='MV-text1'>
          <p>{t("AU_OUR_MISSION_TEXT")}</p>
          <span></span>
        </div>
      </div>
      <div className='MV-vision'>
        <div className='MV-text2'>
          <span></span>
          <p>{t("AU_OUR_VISION_TEXT")}</p>
        </div>
        <h2 style={{borderRadius:'70px 70px 0 0'}}><div className='MV-Text'>{t("AU_OUR_VISION_HEADER")}</div></h2>
      </div>
    </section>
  )
}

export default MissionVision
