const globeIcon = (props : {color : string}) => {
  const { color = '#000'  } = props
    return (
      <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1.06103 9.88889H3.22222C4.44952 9.88889 5.44444 10.8838 5.44444 12.1111V13.2222C5.44444 14.4495 6.43937 15.4444 7.66667 15.4444C8.89397 15.4444 9.88889 16.4394 9.88889 17.6667V20.939M6.55556 2.03947V3.77778C6.55556 5.3119 7.79921 6.55556 9.33333 6.55556H9.88889C11.1162 6.55556 12.1111 7.55048 12.1111 8.77778C12.1111 10.0051 13.106 11 14.3333 11C15.5606 11 16.5556 10.0051 16.5556 8.77778C16.5556 7.55048 17.5505 6.55556 18.7778 6.55556L19.9605 6.55555M14.3333 20.431V17.6667C14.3333 16.4394 15.3283 15.4444 16.5556 15.4444H19.9605M21 11C21 16.5228 16.5228 21 11 21C5.47715 21 1 16.5228 1 11C1 5.47715 5.47715 1 11 1C16.5228 1 21 5.47715 21 11Z" stroke={color} stroke-linecap="round" stroke-linejoin="round"/>
</svg>
    );
  };

  export default globeIcon; 
