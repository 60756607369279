import { useNavigate } from "react-router-dom";
import "./Footer.css";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const routes = {
    OurServices: "Our Services",
    AboutUs: "About Us",
    ContactUs: "Contact Us",
  };

  const socialMedia = {
    Facebook: "Facebook",
    Instagtam: "Instagram",
    Twitter: "Twitter",
    LinkedIn: "LinkedIn",
    Zalo: "Zalo",
  };

  const socialMediaLinks = {
    [socialMedia.Facebook]:
      "https://www.facebook.com/people/VnRoyal-Technology/61562117399670/",
    [socialMedia.Instagtam]: "",
    [socialMedia.Twitter]: "https://x.com/VnroyalT",
    [socialMedia.LinkedIn]:
      "https://www.linkedin.com/in/vnroyal-technology-327a15317/",
    [socialMedia.Zalo]: "",
  };

  const handleNavigate = (flag?: string) => {
    let pathname = "/";

    switch (flag) {
      case routes.OurServices:
        pathname = "/ourservices";
        break;
      case routes.AboutUs:
        pathname = "/about";
        break;
      case routes.ContactUs:
        pathname = "/contact";
        break;
      default:
        pathname = "/";
    }
    navigate(pathname);
  };

  const handleSocialMediaClick = (flag: string) => {
    const url = socialMediaLinks[flag];
    window.open(url, "_blank");
  };

  return (
    <div className="vn-royal-footer">
      <div className="footer-logo">
        <img
          src="./VnRoyalLogo.png"
          alt="footer-logo-png"
          className="pointer"
          onClick={() => handleNavigate()}
        ></img>
      </div>
      <div className="footer-social-media">
        <div className="footer-nav-links">
          <div
            className="pointer"
            onClick={() => handleNavigate(routes.OurServices)}
          >
            {t("FOOTER_NAV_TEXT1")}
          </div>
          <div
            className="pointer"
            onClick={() => handleNavigate(routes.AboutUs)}
          >
            {t("FOOTER_NAV_TEXT2")}
          </div>
          <div
            className="pointer"
            onClick={() => handleNavigate(routes.ContactUs)}
          >
            {t("FOOTER_NAV_TEXT3")}
          </div>
        </div>
        <div className="footer-space-line"></div>
        <div className="footer-follow-us">{t("FOOTER_SOCIAL_MEDIA_TEXT")}</div>
        <div className="footer-social-media-icons">
          <img
            src="./Fb.png"
            alt={socialMedia.Facebook}
            className="pointer"
            onClick={() => handleSocialMediaClick(socialMedia.Facebook)}
          ></img>
          <img
            src="./instagram.png"
            alt={socialMedia.Instagtam}
            className="pointer"
            onClick={() => handleSocialMediaClick(socialMedia.Instagtam)}
          ></img>
          <img
            src="./twitter.png"
            alt={socialMedia.Twitter}
            className="pointer"
            onClick={() => handleSocialMediaClick(socialMedia.Twitter)}
          ></img>
          <img
            src="./Linkedin.png"
            alt={socialMedia.LinkedIn}
            className="pointer"
            onClick={() => handleSocialMediaClick(socialMedia.LinkedIn)}
          ></img>
          <img
            src="./Zalo.png"
            alt={socialMedia.Zalo}
            className="pointer"
            onClick={() => handleSocialMediaClick(socialMedia.Zalo)}
          ></img>
        </div>
        <div className="footer-copy-rights">
          Copy right 2024 | Privacy Policy | Terms & Condition
        </div>
      </div>
      <div className="footer-contact-info">
        <div className="get-in-touch">{t("FOOTER_TEXT")}</div>
        <div className="contact-info">
          E-Mail:{" "}
          <a href="mailto:technology@vnroyal.com">technology@vnroyal.com</a>
        </div>
        <div className="contact-info">Phone: +84 0778821234</div>
      </div>
    </div>
  );
};

export default Footer;
