import { useTranslation } from 'react-i18next';
import { LoadingChevronDown } from '../../components/LoadingChevronDown';
import './Homepage.css';

const HPBannerSection: React.FC = () => {
	const { t } = useTranslation();
	return (
		<div className='HP-Banner'>
		 <div className='Background'>
		  <div className='HP-screen'>
		  <div className='texts'>
			  <h1>{t("HP_BANNER_TEXT1")}</h1>
              <h3>
			     {t("HP_BANNER_TEXT2")}
			  </h3>
			</div>
			<div className='banner-image'>
				<img src='./HomePageBannerImage.jpg' alt='HomepageBanner' />
			</div>
			
		  </div>
		 </div>
			 <LoadingChevronDown />
		</div>
	);
};

export { HPBannerSection };
