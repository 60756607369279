import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, Routes as ReactRoutes, Route } from "react-router-dom";
import { HomePage } from "./pages/HomePage/HomePage";
import "./router.css";
import NavBar from "./components/NavBar";
import AboutUsPage from "./pages/AboutUs/AboutUsPage";
import ContactUs from "./pages/ContactUs/ContactUs";
import OurServicesPage from "./pages/OurServices/OurServicesPage";
import Footer from "./components/Common/Footer";

function Layout() {
  const { t } = useTranslation();

  useEffect(() => {
    const handleScroll = () => {
      const navbar = document.getElementById("navbar");

      if (navbar) {
        if (window.scrollY > 200) {
          // Adjust this value based on when you want the color to change
          navbar.classList.add("scrolled");
        } else {
          navbar.classList.remove("scrolled");
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="layout">
      <NavBar logoSrc="./Logo VNRTech.jpeg" language="English" />
      <Outlet />
      <Footer />
    </div>
  );
}

const Routes: React.FC = () => {
  return (
    <ReactRoutes>
      <Route element={<Layout />}>
        <Route path="/" element={<HomePage />} />
        <Route path="/about" element={<AboutUsPage />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/ourservices" element={<OurServicesPage />} />
      </Route>
    </ReactRoutes>
  );
};

export default Routes;
