// @ts-nocheck
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import "./OurValues.css";
import { useTranslation } from "react-i18next";

const OurValues: React.FC = () => {
  const { t } = useTranslation();
  const content = useMemo(() => {
    return [
      {
        title: t("AU_OUR_VALUES_TEXT1"),
        text: t("AU_OUR_VALUES_P1"),
      },
      {
        title: t("AU_OUR_VALUES_TEXT2"),
        text: t("AU_OUR_VALUES_P2"),
      },
      {
        title: t("AU_OUR_VALUES_TEXT3"),
        text: t("AU_OUR_VALUES_P3"),
      },
      {
        title: t("AU_OUR_VALUES_TEXT4"),
        text: t("AU_OUR_VALUES_P4"),
      },
    ];
  }, [t]);

  const [currentIndex, setCurrentIndex] = useState(1);

  const currentValues = useMemo(() => {
    const { title, text } = content[currentIndex];
    return { title, text };
  }, [currentIndex, content]);

  const handleSelectSlide = (index: number) => {
    setCurrentIndex(index);
    startSlideShow();
  };

  const intervalIdRef = useRef(null);

  const startSlideShow = useCallback(() => {
    clearInterval(intervalIdRef.current);
    const intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) => {
        if (prevIndex === content.length - 1) {
          return 0;
        } else {
          return prevIndex + 1;
        }
      });
    }, 5000);
    intervalIdRef.current = intervalId;
  }, [content]);

  useEffect(() => {
    startSlideShow();

    return () => {
      clearInterval(intervalIdRef.current);
    };
  }, [content, startSlideShow]);

  return (
    <section className="OV-Background">
      <div className="OV-Screen">
        <span></span>
        <h1> {t("AU_OUR_VALUES_HEADER")}</h1>
        <span></span>
      </div>
      <div className="OV-Texts">
        <div className="large-screen">
          {content.map((content, i) => {
            const { title, text } = content;
            return (
              <div className={`text-${i + 1}`} key={i}>
                <h2>{title}</h2>
                <h5>{text}</h5>
              </div>
            );
          })}
        </div>

        <div className="small-carousel">
          <div className="sm-our-values">
            <h2>{currentValues.title}</h2>
            <h5>{currentValues.text}</h5>
          </div>
          <div className="sm-our-values-circles">
            {content.map((_, i) => {
              return (
                <i
                  className={`fa-${
                    currentIndex === i ? "solid" : "regular"
                  } fa-circle pointer`}
                  key={i}
                  onClick={() => handleSelectSlide(i)}
                ></i>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurValues;
