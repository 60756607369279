import React from 'react'
import './OurServices.css'
import { LoadingChevronDown } from '../../components/LoadingChevronDown'
import { useTranslation } from 'react-i18next';
const OurServices: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div className='OS-background'>
      <div className='OS-Text-web'>
        <h1 >
          <span className="OS-Text">{t("OS_BANNER_WEB_TEXT1")}</span>
          <span className="OS-Text" >{t("OS_BANNER_WEB_TEXT2")}</span>
          <span className="OS-Text" >{t("OS_BANNER_WEB_TEXT3")}</span>
        </h1>
      </div>

      <div className='OS-Text-Mobile'>
        <h4>{t("OS_BANNER_MOB_TEXT1")}</h4>
        <h4>{t("OS_BANNER_MOB_TEXT2")}</h4>
        <h2>{t("OS_BANNER_MOB_TEXT3")}</h2>
      </div>

      <div className='OS-Text-Tab'>
        <h3>{t("OS_BANNER_TAB_TEXT1")}</h3>
        <h2> {t("OS_BANNER_TAB_TEXT2")}</h2>
      </div>

      <LoadingChevronDown />
    </div>
  )
}

export default OurServices
