import React, { useState } from "react";

const useContactUsForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    emailId: "",
    subject: "",
    message: "",
  });

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({} as any);

  const handleFormDataChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    setErrors({});
  };

  const validate = (formData: any) => {
    let errors: any = {};

    if (!formData.name || formData.name.trim() === "") {
      errors.name = "Name is required";
    }

    if (!formData.emailId || formData.emailId.trim() === "") {
      errors.emailId = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.emailId)) {
      errors.emailId = "Email is invalid";
    }

    if (!formData.subject || formData.subject.trim() === "") {
      errors.subject = "Subject is required";
    }

    if (!formData.message || formData.message.trim() === "") {
      errors.message = "Message is required";
    }

    return errors;
  };

  const handleSubmitForm = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setLoading(true);
    const { name, emailId, subject, message } = formData;
    const errors = validate(formData);
    if (Object.keys(errors).length !== 0) {
      console.log("errors", errors);
      setErrors(errors);
      setLoading(false);
      return;
    }
    // Define the URL and the data to be sent
    const url = process.env.REACT_APP_EMAILJS_API_URL as string;
    const data = {
      service_id: process.env.REACT_APP_EMAILJS_SERVICE_ID as string,
      template_id: process.env.REACT_APP_EMAILJS_TEMPLATE_ID as string,
      user_id: process.env.REACT_APP_EMAILJS_USER_ID,
      template_params: {
        from_name: name,
        reply_to: emailId,
        subject,
        message,
        to_name: "VnRoyal Technologies",
      },
    };

    // Make the POST request using fetch
    try {
      const response = await fetch(url, {
        method: "POST", // Specify the request method
        headers: {
          "Content-Type": "application/json", // Set the content type to JSON
        },
        body: JSON.stringify(data), // Convert the data to a JSON string
      });
      const resData = response.json();
      console.log("Success:", resData);
    } catch (e) {
      console.error("Error:", e);
    }

    setTimeout(async () => {
      const data = {
        service_id: process.env.REACT_APP_EMAILJS_SERVICE_ID as string,
        template_id: process.env
          .REACT_APP_EMAILJS_CONFIRMATION_TEMPLATE_ID as string,
        user_id: process.env.REACT_APP_EMAILJS_USER_ID,
        template_params: {
          from_name: name,
          reply_to: emailId,
        },
      };
      try {
        const response = await fetch(url, {
          method: "POST", // Specify the request method
          headers: {
            "Content-Type": "application/json", // Set the content type to JSON
          },
          body: JSON.stringify(data), // Convert the data to a JSON string
        });
        const resData = response.json();
        console.log("Success:", resData);
      } catch (e) {
        console.error("Error:", e);
      }
    }, 2000);
    setFormData({ name: "", emailId: "", subject: "", message: "" });
    setLoading(false);
    alert("Email Sent Successfully!");
  };
  return {
    formData,
    handleFormDataChange,
    handleSubmitForm,
    loading,
    errors,
  };
};

export default useContactUsForm;
