import React from "react";
import OurServices from "./OurServices";
// import MiniContact from "./MiniContact";
import OptimalProcess from "./OptimalProcess";

const OurServicesPage: React.FC = () => {
  return (
    <>
      <OurServices />
      <OptimalProcess />
      {/* <MiniContact /> */}
    </>
  );
};

export default OurServicesPage;
