import React from "react";
import './LoadingChevronDown.css'


const LoadingChevronDown: React.FC<{}> = () => {
    return (
        <div className='icons'>
            <div className='icon1'>
                <i className='fa-solid fa-chevron-down'></i>
            </div>
            <div className='icon2'>
                <i className='fa-solid fa-chevron-down'></i>
            </div>
            <div className='icon3'>
                <i className='fa-solid fa-chevron-down'></i>
            </div>
            <div className='icon4'>
                <i className='fa-solid fa-chevron-down'></i>
            </div>
        </div>
    )
}

export { LoadingChevronDown };