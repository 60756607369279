import React from 'react'
import AboutUs from './AboutUs'
import OurLead from './OurLead'
import MissionVision from './MissionVision'
import OurValues from './OurValues'
import OurPartners from './OurPartners'

const AboutUsPage: React.FC = () => {
  return (
    <>
      <AboutUs />
      <OurLead />
      <MissionVision />
      <OurValues />
      <OurPartners />
    </>
  )
}

export default AboutUsPage
