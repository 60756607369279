import { GlobeIcon } from "../../icons";
import i18n from "../../i18n";
import "./LanguageDropdown.css";

const LanguageSelector = (props: { color: string }) => {
  const { color } = props;

  return (
    <div className="language-selector">
      <span className="icon">
        <GlobeIcon color={color} />
      </span>
      <select
        value={i18n.language}
        className={color === "black" ? "chevron-black" : "chevron-white"}
        onChange={(event) => i18n.changeLanguage(event.target.value)}
      >
        <option value="en">English</option>
        <option value="vi">Vietnamese</option>
      </select>
    </div>
  );
};

export default LanguageSelector;
