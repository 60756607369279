// @ts-nocheck
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import "./OptimalProcess.css";
import useOurServicesTranslatedContent from "../../hooks/useOurServicesTranslatedContent";

const OptimalProcess = () => {
  const { t } = useTranslation();
  const { ourServicesContent: ourServices } = useOurServicesTranslatedContent();
  const [currentIdx, setCurrentIdx] = useState(0);
  const processContentRef = useRef();
  const toggleIdx = (idx) => {
    processContentRef.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
    if (currentIdx === idx) {
      setCurrentIdx();
    } else {
      setCurrentIdx(idx);
    }
  };
  return (
    <div className="optimal-process">
      <h2 className="optimal-proces-header" ref={processContentRef}>
        {t("OS_TEXT2")}
      </h2>
      {Object.keys(ourServices).map((serviceName, index) => {
        return (
          <Accordion
            serviceName={serviceName}
            ourServices={ourServices}
            currentIdx={currentIdx}
            toggleIdx={() => toggleIdx(index)}
            index={index}
            key={index}
          />
        );
      })}
    </div>
  );
};

export default OptimalProcess;

function ProcessStep({
  stepNo,
  textContent,
  textHeader,
}: {
  stepNo?: number;
  textContent?: string;
  textHeader?: string;
}) {
  return (
    <div className="process-step">
      <div className="process-step-no">{stepNo}</div>
      <div className="process-step-content">
        <strong>{textHeader}</strong> {textContent}
      </div>
    </div>
  );
}

function Accordion({ serviceName, ourServices, currentIdx, toggleIdx, index }) {
  const contentRef = useRef(null);
  return (
    <div className="accordion">
      <div className="accordion-item">
        <div className="accordion-header pointer" onClick={toggleIdx}>
          {serviceName}{" "}
          <i
            className={`
            fa-solid fa-caret-${currentIdx === index ? "up" : "down"}`}
          ></i>
        </div>
        <div
          className="accordion-body"
          ref={contentRef}
          style={{
            maxHeight:
              currentIdx === index ? contentRef?.current?.scrollHeight : "0px",
          }}
        >
          <div className="text-content">
            {ourServices?.[serviceName].headerText}
          </div>
          {ourServices?.[serviceName].steps.map((step, i) => {
            const { stepNo, textContent, textHeader } = step;
            return (
              <ProcessStep
                stepNo={stepNo}
                textContent={textContent}
                textHeader={textHeader}
                key={i}
              />
            );
          })}
          <div className="text-content">
            {ourServices?.[serviceName].footerText}
          </div>
        </div>
      </div>
    </div>
  );
}
