import { useTranslation } from "react-i18next";

const useOurServicesTranslatedContent = () => {
  const { t } = useTranslation();

  const ourServicesContent = {
    [t("OS_TEXT_BUTTON1")]: {
      headerText: t("OS_TEXT_INSIDE_WEB"),
      steps: [
        {
          stepNo: 1,
          textHeader: t("WEB_DISCOVERY_PHASE_TITLE"),
          textContent: t("WEB_DISC_TEXT"),
        },
        {
          stepNo: 2,
          textHeader: t("WEB_PLANNING_PHASE_TITLE"),
          textContent: t("WEB_PLAN_TEXT"),
        },
        {
          stepNo: 3,
          textHeader: t("WEB_DESIGN_PHASE"),
          textContent: t("WEB_DESIGN_TEXT"),
        },
        {
          stepNo: 4,
          textHeader: t("WEB_DEV_PHASE_TITLE"),
          textContent: t("WEB_DEV_TEXT"),
        },
        {
          stepNo: 5,
          textHeader: t("WEB_TESTING_TITLE"),
          textContent: t("WEB_TEST_TEXT"),
        },
        {
          stepNo: 6,
          textHeader: t("WEB_DEPLOY_PHASE"),
          textContent: t("WEB_DEPLOY_TEXT"),
        },
      ],
      footerText: t("WEB_FOOTER_TEXT"),
    },
    [t("OS_TEXT_BUTTON2")]: {
      headerText: t("OS_TEXT_INSIDE_DM"),
      steps: [
        {
          stepNo: 1,
          textHeader: t("DM_DISC_PHASE"),
          textContent: t("DM_DISC_TEXT"),
        },
        {
          stepNo: 2,
          textHeader: t("DM_PLANNING_PHASE_TITLE"),
          textContent: t("DM_PLAN_TEXT"),
        },
        {
          stepNo: 3,
          textHeader: t("DM_EXECUTION_TITLE"),
          textContent: t("DM_EXECUTION_TEXT"),
        },
        {
          stepNo: 4,
          textHeader: t("DM_MA_PHASE_TITLE"),
          textContent: t("DM_MA_TEXT"),
        },
        {
          stepNo: 5,
          textHeader: t("DM_CA_PHASE_TITLE"),
          textContent: t("DM_CA_PHASE_TEXT"),
        },
      ],
      footerText: t("DM_FOOTER_TEXT"),
    },
    [t("OS_TEXT_BUTTON3")]: {
      headerText: t("OS_TEXT_INSIDE_UIUX"),
      steps: [
        {
          stepNo: 1,
          textHeader: t("UIUX_DISC_TITLE"),
          textContent: t("UIUX_DISC_TEXT"),
        },
        {
          stepNo: 2,
          textHeader: t("UIUX_PLAN_TITLE"),
          textContent: t("UIUX_PLAN_TEXT"),
        },
        {
          stepNo: 3,
          textHeader: t("UIUX_DESIGN_TITLE"),
          textContent: t("UIUX_DESIGN_TEXT"),
        },
        {
          stepNo: 4,
          textHeader: t("UIUX_TEST_TITLE"),
          textContent: t("UIUX_TEST_TEXT"),
        },
        {
          stepNo: 5,
          textHeader: t("UIUX_IMP_TITLE"),
          textContent: t("UIUX_IMP_TEXT"),
        },
      ],
      footerText: t("UIUX_FOOTER_TEXT"),
    },
  };
  return { ourServicesContent };
};

export default useOurServicesTranslatedContent;
