import React from 'react'
import'./AboutUs.css'
import { LoadingChevronDown } from '../../components/LoadingChevronDown'
import { useTranslation } from 'react-i18next';
const AboutUs: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div className='AU-Background'>
        {/* <div className='AU-Background-color'> */}
          <div className='AU-Text-web'>
            <h1>{t("AU_BANNER_TEXT1")}</h1>
          </div>
          <div className='AU-Text-Mobile'>
            <h4>{t("AU_MOB_TEXT1")}</h4>
            <h2>{t("AU_MOB_TEXT2")}</h2>
            <h4>{t("AU_MOB_TEXT3")}</h4>
          </div>
           <LoadingChevronDown />
    </div>
  )
}

export default AboutUs

